import './App.css';
import HeitUpTitleCollection from "./ui-components/HeitUpTitleCollection"
import HeitUpHeadingCollection from "./ui-components/HeitUpHeadingCollection"
import HeitUpServiceCollection from "./ui-components/HeitUpServiceCollection"
import HeitUpPricingCollection from "./ui-components/HeitUpPricingCollection"
import HeitUpContactCollection from "./ui-components/HeitUpContactCollection"
import { Link } from "react-router-dom";
import TagManager from 'react-gtm-module';

const lineLinkClick = () => {
    TagManager.dataLayer({
        dataLayer: {
            event: 'line_link_click',
            linkType: 'external',
            linkName: '無料の減量猫（お試し版）を友達追加！'
        }
    });
}

export const Home = () => {
    return (
        <div className="Home">
            <HeitUpTitleCollection/>
            <HeitUpHeadingCollection/>
            <HeitUpServiceCollection/>
            <ul className="md-footer-nav__menu">
                <li className="md-footer-nav__item">
                    <Link
                        to="https://liff.line.me/1645278921-kWRPP32q/?accountId=009etger"
                        onClick={lineLinkClick}
                    >
                        無料の減量猫（お試し版）を友達追加！
                    </Link>
                </li>
            </ul>
            <HeitUpPricingCollection/>
            <HeitUpContactCollection/>
            <ul className="md-footer-nav__menu">
                <li className="md-footer-nav__item">
                    <Link to="/privacy_policy">プライバシーポリシー</Link>
                </li>
                <li className="md-footer-nav__item">
                    <Link to="/terms_of_use">利用規約</Link>
                </li>
            </ul>
        </div>
    );
}
