import { useEffect, useState } from 'react';
import { marked } from 'marked';
import markdownText from './fasting_info.md';

export const FastingInfo = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(markdownText)
      .then(response => response.text())
      .then(text => {
        setContent(marked(text));
      });
  }, []);

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
};
