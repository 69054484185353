// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { HeitUpHeading, HeitUpPricing, HeitUpContact, HeitUpService, HeitUpTitle } = initSchema(schema);

export {
  HeitUpHeading,
  HeitUpPricing,
  HeitUpContact,
  HeitUpService,
  HeitUpTitle
};